
import './header-cp.css';
import Navbar from '../Navbar/index';

const HeaderCp = () => {
    return (
        <section className="our__coffee">
            <Navbar/>
            <h3>Our Coffee</h3>
        </section>
    )
}
export default HeaderCp;