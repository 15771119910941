
import './beanslogoblack.css';
import beanslogo from '../images/Beans logo.svg';

const BeansLogoBlack = () => {
    return (
        <div className="beans">
        <img src={beanslogo} alt="beans logo" className="beans-logo"/>               
       </div> 
    );
}

export default BeansLogoBlack;